import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../../../components/elements/Paginate/Paginate";
import {toast} from "react-toastify";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import Selector from "../../../../../components/elements/Selector/Selector";
import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function FoodMenus() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')

    const [menus, setMenus] = useState([])
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [type, setType] = useState('')
    const [eventTypes, setEventTypes] = useState([])
    const [order, setOrder] = useState('price_asc')
    const [totalMenus, setTotalMenus] = useState(0)
    const [euro, setEuro] = useState(0)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)


    useEffect(() => {
        getEventTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page, order,type])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/food-menus/list/' + page, {search, type,order}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setMenus(response.data.menus ?? [])
                setTotalMenus(response.data.total ?? 0)
                setEuro(response.data.eur.value)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getEventTypes = async () => {
        axios.get(process.env.REACT_APP_API + '/event-types' , {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i=0; i < response.data.length; i++){
                    temp.push({
                        value: response.data[i].id,
                        label: intl.formatMessage({id: 'general.event_type.' + response.data[i].title})
                    })
                }
                setType(temp.map(item => item.value))
                setEventTypes(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const deleteItem = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/food-menus/' + itemId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'menus.error_delete_menu'}))
                }
                setModalIsOpen(false)
                setItemId(0)
                setLoadingDelete(false)
            })
            .catch(err => {
                setLoadingDelete(false)
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-lg-4 col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-lg-8 col-sm-12 float-right"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/menus/food-menus/add/0')}
                        />
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <div className={"form-control search-input-container selector-event-type"}>
                                <div className={"form-label"}>
                                    {intl.formatMessage({id: 'menus.table.type'})}
                                </div>

                                <Selector
                                    options={eventTypes}
                                    style={{maxHeight: '38px'}}
                                    value={type}
                                    onChange={(option) => setType(Array.from(option, option => parseInt(option.value)))}
                                    isSearchable={true}
                                    required
                                    isMulti
                                    hideSelectedOptions={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <div className={"form-control search-input-container"}>
                                <div className={"form-label"}>
                                    {intl.formatMessage({id: 'menus.display_order'})}
                                </div>
                                <Selector
                                    options={[
                                        {
                                            value: 'price_asc',
                                            label: intl.formatMessage({id: 'general.menu_order.price_asc'})
                                        },
                                        {
                                            value: 'price_desc',
                                            label: intl.formatMessage({id: 'general.menu_order.price_desc'})
                                        },
                                        {
                                            value: 'name_asc',
                                            label: intl.formatMessage({id: 'general.menu_order.name_asc'})
                                        },
                                        {
                                            value: 'name_desc',
                                            label: intl.formatMessage({id: 'general.menu_order.name_desc'})
                                        },
                                        {
                                            value: 'date_asc',
                                            label: intl.formatMessage({id: 'general.menu_order.date_asc'})
                                        },
                                        {
                                            value: 'date_desc',
                                            label: intl.formatMessage({id: 'general.menu_order.date_desc'})
                                        },
                                    ]}
                                    value={order}
                                    onChange={(option) => setOrder(option.value)}
                                    isSearchable={true}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            {
                menus.length === 0 ?
                    search === '' ?
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'menus.alert_no_food_menu'})}</div>
                        </div>
                        :
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'menus.alert_no_food_menu_found'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table menus mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div
                                        className={"col-2 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'general.price'})}</div>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'menus.table.type'})}</div>
                                    <div className={"col-3 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    menus.map((menu, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1 + (page - 1) * 10}
                                                </div>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {menu.title}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {parseFloat(menu.price).toFixed(2)} {intl.formatMessage({id: 'settings.currency.' + currency})}

                                                    {
                                                        currency === 'euro' ?
                                                        <p className={"ms-2"}> ({parseFloat(menu.price * euro).toFixed(2)} {intl.formatMessage({id: 'settings.currency.ron'})})</p>
                                                            :
                                                            <p className={"ms-2"}> ({parseFloat(menu.price / euro).toFixed(2)} {intl.formatMessage({id: 'settings.currency.euro'})})</p>
                                                    }
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {
                                                        menu.event_types.map((item,i) => {
                                                            return intl.formatMessage({id: 'general.event_type.' + item.title}) + (i !== menu.event_types.length - 1 ? ', ' : '')
                                                        })
                                                    }
                                                </div>
                                                <div className={"col-3 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/menus/food-menus/' + menu.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        icon={"fa-solid fa-trash"}
                                                        onClick={() => {
                                                            setModalIsOpen(true)
                                                            setItemId(menu.id)
                                                        }}
                                                    />

                                                    <Button
                                                        className={"btn-light btn-more"}
                                                        icon={"fa-solid fa-copy"}
                                                        tooltip={intl.formatMessage({id: 'general.copy'})}
                                                        onClick={() => navigate('/menus/food-menus/add/' + menu.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-more"}
                                                        icon={"fa-solid fa-ellipsis-vertical"}
                                                        tooltip={intl.formatMessage({id: 'general.view'})}
                                                        onClick={() => navigate('/menus/food-menus/view/' + menu.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>

                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalMenus}
                />
            </div>
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => {
                    setModalIsOpen(false)
                    setItemId(0)
                }}
                onConfirm={() => deleteItem()}
            />
        </div>

    );
}

