import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function Taxes() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [taxes, setTaxes] = useState([])

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/taxes', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setTaxes(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const deleteItem = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/taxes/' + itemId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'settings.taxes.error_delete_tax'}))
                }
                setModalIsOpen(false)
                setItemId(0)
                setLoadingDelete(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete(false)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.taxes'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header align-items-center"}>
                            <div></div>
                            <Button
                                className={"d-block btn-primary ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/taxes/add')}
                            />
                        </div>


                        {
                            taxes.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'settings.taxes.alert_no_taxes'})}
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-4 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                                <div className={"col-4 table-td"}>{intl.formatMessage({id: 'menus.event_type'})}</div>
                                                <div className={"col-4 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                taxes.map((tax, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-4 table-td"}>{tax.title}</div>
                                                            <div
                                                                className={"col-4 table-td"}>
                                                                {
                                                                    tax.event_types.map((type, i) => {
                                                                        return (intl.formatMessage({id: 'general.event_type.' + type.title})
                                                                            + (i !== tax.event_types.length - 1 ? ', ' : '')
                                                                        )
                                                                    })
                                                                }</div>
                                                            <div className={"col-4 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/taxes/' + tax.id)}
                                                                />

                                                                <Button
                                                                    className={"btn-light btn-delete"}
                                                                    icon={"fa-solid fa-trash"}
                                                                    tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                    onClick={() => {
                                                                        setModalIsOpen(true)
                                                                        setItemId(tax.id)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer"}>

                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => {
                    setModalIsOpen(false)
                    setItemId(0)
                }}
                onConfirm={() => deleteItem()}
            />
        </div>
    );
}

