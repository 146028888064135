import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import './Invoices.scss';
import moment from "moment";
import InvoiceInfo from "../../../../../../components/Invoice/InvoiceInfo";
import LineItems from "../../../../../../components/Invoice/LineItems";
import InvoiceTable from "../../../../../../components/Invoice/InvoiceTable";
import DatePicker from "react-datepicker";
import Selector from "../../../../../../components/elements/Selector/Selector";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import {components} from "react-select";
import {getAddress} from "../../../../../../utils/functions";

export default function AddInvoice() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();
    const currency = localStorage.getItem('currency')

    const [event, setEvent] = useState([])
    const [number, setNumber] = useState('')
    const date = new Date()
    const [itemsSelector, setItemsSelector] = useState([])
    const [items, setItems] = useState([])
    const [series, setSeries] = useState('')
    const [seriesOptions, setSeriesOptions] = useState([])
    const [total, setTotal] = useState(0)
    const [itemsOptions, setItemsOptions] = useState([])
    const [dueDate, setDueDate] = useState(new Date())
    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [update, setUpdate] = useState(0)
    const [vats, setVats] = useState([])
    const [totals, setTotals] = useState([])
    const [vatsOptions, setVatsOptions] = useState([])
    const [eur, setEur] = useState(0)
    const {context} = useOutletContext()



    useEffect(() => {
        if (currency === 'euro')
            getEur()
        else
            getStaffCategories()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
            getStaffCategories()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eur])
    const getStaffCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/staff-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getStaff(response.data)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getStaff = (staffCategories) => {
        axios.post(process.env.REACT_APP_API + '/staff-users/list/0', {search: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.staff.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.name,
                        staff_category_id: item.staff_category.id,
                        phone: item.phone,
                        price: parseFloat(item.price * (currency === 'euro' ? eur : 1)).toFixed(2),
                    })
                })
                getEvent(staffCategories,items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getEvent = async (staffCategories,items) => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setEvent(response.data)
                let options = [];
                let total = 0;
                let price = 0;

                if (response.data.food_menu_to_events) for (let i = 0; i < response.data.food_menu_to_events.length; i++) {
                    price = response.data.food_menu_to_events[i].custom_price ? response.data.food_menu_to_events[i].price : response.data.food_menu_to_events[i].food_menu.price
                    options.push({
                        value: response.data.food_menu_to_events[i].type + '_menus_' + response.data.food_menu_to_events[i].food_menu.id,
                        label: intl.formatMessage({id: 'events.costs.' + response.data.food_menu_to_events[i].type + '_menus'}) + ': ' + response.data.food_menu_to_events[i].food_menu.title,
                        price: parseFloat(parseFloat(price * (currency === 'euro' ? eur : 1)).toFixed(2)),
                        qty: response.data.food_menu_to_events[i].qty,
                        type: 'food_menu',
                        id: response.data.food_menu_to_events[i].food_menu.id,
                        vat: response.data.food_menu_to_events[i].food_menu.vat.value
                    })

                    total += parseFloat(parseFloat(price * response.data.food_menu_to_events[i].qty * (currency === 'euro' ? eur : 1)).toFixed(2))
                }
                if (response.data.drink_menu_to_events) for (let i = 0; i < response.data.drink_menu_to_events.length; i++) {
                    price = response.data.drink_menu_to_events[i].custom_price ? response.data.drink_menu_to_events[i].price_non_alcoholic : response.data.drink_menu_to_events[i].drink_menu.price_non_alcoholic
                    options.push({
                        value: 'drink_menus_non_alcoholic_' + response.data.drink_menu_to_events[i].drink_menu.id,
                        label: intl.formatMessage({id: 'events.costs.non_alcoholic_drink_menus'}) + ': ' + response.data.drink_menu_to_events[i].drink_menu.title,
                        price: parseFloat(parseFloat(price * (currency === 'euro' ? eur : 1)).toFixed(2)),
                        qty: response.data.drink_menu_to_events[i].qty,
                        type: 'drink_menu',
                        id: response.data.drink_menu_to_events[i].drink_menu.id,
                        vat: response.data.drink_menu_to_events[i].drink_menu.vat_non_alcoholic.value
                    })
                    total += parseFloat(parseFloat(price* response.data.drink_menu_to_events[i].qty * (currency === 'euro' ? eur : 1)).toFixed(2))

                    price = response.data.drink_menu_to_events[i].custom_price ? response.data.drink_menu_to_events[i].price_alcoholic : response.data.drink_menu_to_events[i].drink_menu.price_alcoholic
                    options.push({
                        value: 'drink_menus_alcoholic_' + response.data.drink_menu_to_events[i].drink_menu.id,
                        label: intl.formatMessage({id: 'events.costs.alcoholic_drink_menus'}) + ': ' + response.data.drink_menu_to_events[i].drink_menu.title,
                        price: parseFloat(parseFloat(price * (currency === 'euro' ? eur : 1)).toFixed(2)),
                        qty: response.data.drink_menu_to_events[i].qty,
                        type: 'drink_menu',
                        id: response.data.drink_menu_to_events[i].drink_menu.id,
                        vat: response.data.drink_menu_to_events[i].drink_menu.vat.value
                    })
                    total += parseFloat(parseFloat(price * response.data.drink_menu_to_events[i].qty * (currency === 'euro' ? eur : 1)).toFixed(2))

                    total = parseFloat(parseFloat(total).toFixed(2))
                }


                if (response.data.products) for (let i = 0; i < response.data.products.length; i++) {
                    options.push({
                        value: 'product_' + response.data.products[i].id,
                        label: intl.formatMessage({id: 'events.menu.logistics'}) + ': ' + response.data.products[i].product.title,
                        price: parseFloat(parseFloat(response.data.products[i].price * (currency === 'euro' ? eur : 1)).toFixed(2)),
                        qty: response.data.products[i].type === 'per_event' ? response.data.products[i].qty : response.data.number_of_guests,
                        type: 'product',
                        id: response.data.products[i].product.id,
                        vat: response.data.products[i].vat_value
                    })

                    total += parseFloat(parseFloat((response.data.products[i].type === 'per_event' ? response.data.products[i].qty : response.data.number_of_guests) * response.data.products[i].price * (currency === 'euro' ? eur : 1)).toFixed(2))
                }
                if (response.data.service_packages) for (let i = 0; i < response.data.service_packages.length; i++) {
                    for (let j = 0; j < response.data.service_packages[i].totals.length; j++) {
                        let item = response.data.service_packages[i].totals[j]
                        options.push({
                            value: 'service_package_' + response.data.service_packages[i].id + '_' + item.vat_percent,
                            label: intl.formatMessage({id: 'events.menu.logistics'}) + ': ' + response.data.service_packages[i].service_package.title + '(' + item.vat_percent + '%)',
                            price: parseFloat(parseFloat(item.total_without_vat * (currency === 'euro' ? eur : 1)).toFixed(2)),
                            qty: response.data.service_packages[i].type === 'per_event' ? 1 : response.data.number_of_guests,
                            type: 'service_package',
                            id: response.data.service_packages[i].service_package.id,
                            vat: item.vat_percent
                        })

                        total += parseFloat(parseFloat((response.data.service_packages[i].type === 'per_event' ? 1 : response.data.number_of_guests) * (item.total_without_vat) * (currency === 'euro' ? eur : 1)).toFixed(2))
                    }

                }

                if (response.data.staff_categories) {
                    for (let i = 0; i < response.data.staff_categories.length; i++) {
                        if (response.data.price_staff_for_categories) {
                            if (response.data.staff_categories[i].price && parseFloat(response.data.staff_categories[i].price) > 0) {
                                options.push({
                                    value: 'staff_category_' + response.data.staff_categories[i].category_id,
                                    label: intl.formatMessage({id: 'events.menu.staff'}) +
                                        ' (' + (staffCategories.find(item => item.id === response.data.staff_categories[i].category_id) &&
                                            staffCategories.find(item => item.id === response.data.staff_categories[i].category_id).title) + ')',
                                    price: parseFloat(parseFloat(response.data.staff_categories[i].price * (currency === 'euro' ? eur : 1)).toFixed(2)),
                                    qty: response.data.staff_categories[i].users_number,
                                    type: 'staff_category',
                                    id: response.data.staff_categories[i].category_id,
                                    vat: response.data.company.vat_payer ? 19 : 0
                                })
                                total += parseFloat(parseFloat(parseFloat(response.data.staff_categories[i].users_number) * parseFloat(response.data.staff_categories[i].price) * (currency === 'euro' ? eur : 1)).toFixed(2))
                            }
                        } else if (response.data.staff_categories[i].users) {

                            // eslint-disable-next-line
                            response.data.staff_categories[i].users.forEach((user) => {
                                if (user.price && parseFloat(user.price) > 0) {
                                    options.push({
                                        value: 'staff_' + user.id,
                                        label: intl.formatMessage({id: 'events.menu.staff'}) + ' (' + (items.find(item => item.value === user.id) && items.find(item => item.value === user.id).label) + ')',
                                        price: parseFloat(parseFloat(user.price * (currency === 'euro' ? eur : 1)).toFixed(2)),
                                        qty: 1,
                                        type: 'staff',
                                        id: user.id,
                                        vat: response.data.company.vat_payer ? 19 : 0
                                    })
                                    total += parseFloat(parseFloat(user.price * (currency === 'euro' ? eur : 1)).toFixed(2))
                                }
                            })
                        }
                    }
                }
                if (response.data.sheet !== null) {
                    await response.data.sheet.general && response.data.sheet.general.add_taxes && response.data.sheet.general.add_taxes.length > 0 && response.data.sheet.general.add_taxes.forEach((tax) => {
                        options.push({
                            value: tax.id,
                            label: tax.label,
                            price: parseFloat(parseFloat((tax.percent ? getTotalForTax(tax, response.data) : tax.price) * (currency === 'euro' ? eur : 1)).toFixed(2)),
                            qty: tax.type === 'per_person' ? response.data.number_of_guests : 1,
                            type: 'tax',
                            id: tax.id,
                            vat: response.data.company.vat_payer ? 19 : 0
                        })
                        total +=  parseFloat(parseFloat((tax.percent ? getTotalForTax(tax, response.data) : tax.price) * (currency === 'euro' ? eur : 1) * (tax.type === 'per_person' ? response.data.number_of_guests : 1)).toFixed(2))

                    })


                    let infoAdvance = ''
                    if (response.data.contracts && response.data.contracts.length > 0){
                        if (response.data.contracts.length === 1){
                            infoAdvance += ' ' + intl.formatMessage({id: 'general.according_contract'}) + ' '
                        }else{
                            infoAdvance += ' ' + intl.formatMessage({id: 'general.according_contracts'}) + ' '
                        }
                        response.data.contracts.forEach((item,i) => {
                            infoAdvance += item.series + item.number + '/' + moment(item.date).format('DD.MM.YYYY')
                            if (i+1 !== response.data.contracts.length){
                                infoAdvance += ', '
                            }
                        })
                    }
                    options.push({
                        value: 'advance',
                        label: intl.formatMessage({id: 'events.costs.advance'}) + infoAdvance,
                        price: parseFloat(parseFloat((response.data.company.advance_charged * total / 100) * (currency === 'euro' ? eur : 1)).toFixed(2)),
                        qty: 1,
                        type: 'advance',
                        id: 0,
                        vat: response.data.company.vat_payer ? 19 : 0
                    })
                    options.push({
                        value: 'all',
                        label: intl.formatMessage({id: 'events.costs.all_conform_contract'}) + (response.data.contracts.length > 0 ? (' ' + response.data.contracts[0].number + ' / ' + response.data.contracts[0].date) : ''),
                        price: total,
                        qty: 1,
                        type: 'all',
                        id: 0,
                        vat: response.data.company.vat_payer ? 19 : 0
                    })
                }
                setItemsOptions(options)
                getSeries()

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getEur = async () => {
        axios.post(process.env.REACT_APP_API + '/global-settings', {title: 'eur'},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setEur(response.data.value)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getTotalForTax = (tax,event) => {
        let total = 0
        if (tax.percent_of.includes('foods'))
            event.food_menu_to_events && event.food_menu_to_events.length > 0 && event.food_menu_to_events.forEach(item => {
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price : item.food_menu.price) * parseFloat(tax.price) / 100).toFixed(2))
            })
        if (tax.percent_of.includes('drinks'))
            event.drink_menu_to_events && event.drink_menu_to_events.length > 0 && event.drink_menu_to_events.forEach(item => {
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price_non_alcoholic : item.drink_menu.price_non_alcoholic) * parseFloat(tax.price) / 100).toFixed(2))
                total += parseFloat(parseFloat(item.qty * (item.custom_price === true ? item.price_alcoholic : item.drink_menu.price_alcoholic) * parseFloat(tax.price) / 100).toFixed(2))
            })
        if (tax.percent_of.includes('logistics')){

            event.products && event.products.length > 0 && event.products.forEach(item => {
                total += parseFloat(parseFloat((item.type === 'per_event' ? (item.qty * item.price) : event.number_of_guests * item.price) * parseFloat(tax.price) / 100).toFixed(2))
            })
            event.service_packages && event.service_packages.length > 0 && event.service_packages.forEach(item => {
                total += parseFloat(parseFloat((item.type === 'per_event' ? (item.custom_price ? item.price : item.service_package.price) :
                    event.number_of_guests * (item.custom_price ? item.price : item.service_package.price)) * parseFloat(tax.price) / 100).toFixed(2))
            })
        }
        return parseFloat(parseFloat(total).toFixed(2))
    }
    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setVatsOptions(response.data.data)
                getInvoices()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getInvoices = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/find/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.invoices.length > 0) {
                    setModalIsOpen(true)
                    setInvoices(response.data.invoices)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.error) {
                    toast.error(response.data.error)
                } else {
                    let temp = []
                    response.data.data.forEach((item) => {
                        if (item.type === 'Factura' || item.type === 'f') temp.push({
                            value: item.name, label: item.name, number: item.next ?? item.nextNumber,
                        })
                    })
                    setSeriesOptions(temp)
                }
                getVats()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();

        if (series === '') {
            toast.error(intl.formatMessage({id: 'errors.select_invoice_series'}))
            return
        }
        if (items.length === 0) {
            toast.error(intl.formatMessage({id: 'errors.add_products'}))
            return
        }
        setSaveLoading(true)

        let payload = {
            series: series, number: number, date: date, due_date: dueDate, total: total, vats: vats, content: {
                client: {
                    name: event.client_type === 'company' ? event.client.company_title : event.client.first_name + ' ' + event.client.last_name,
                    cui: event.client_type === 'company' ? event.client.fiscal_code : '',
                    address: getAddress(event.client),
                    county: event.client.county,
                    city: event.client.city
                }, company: {
                    name: event.company.title,
                    email: event.company.mailjet_sender_email ?? (event.company.users.length > 0 ? event.company.users[0].email : ''),
                    address_1: event.company.address,
                    city: event.company.city,
                    county: event.company.county,
                    address_2: event.company.city + ', ' + event.company.county,
                    bank_accounts: event.company.bank_accounts,
                    currency: 'ron',
                    cui: event.company.fiscal_code,
                    vat_payer: event.company.vat_payer,
                    reg_com_number: event.company.reg_com_number
                }, items: items, totals
            },
        }
        await axios.post(process.env.REACT_APP_API + '/invoices/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    navigate(-1)
                } else {
                    toast.error(response.data.error)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    const setVatValue = () => {
        let vats_temp = []
        let totals_temp = []
        items.forEach((item) => {
            let currentVat = vats_temp.find((vat) => vat.percent === item.vat_percent)
            let currentTotal = totals_temp.find((vat) => vat.percent === item.vat_percent)
            if (item.vat_percent !== 0) {
                if (currentVat && currentTotal) {
                    let index = -1;
                    vats_temp.forEach((vat, i) => {
                        if (vat.percent === item.vat_percent) {
                            index = i;
                        }
                    })

                    if (index > -1) {
                        vats_temp.splice(index, 1);
                    }
                    vats_temp.push({
                        percent: item.vat_percent,
                        value: parseFloat((item.qty * item.price * (item.vat_percent / 100)) + parseFloat(currentVat.value)).toFixed(2)//
                    })


                    index = -1;
                    totals_temp.forEach((tot, i) => {
                        if (tot.percent === item.vat_percent) {
                            index = i;
                        }
                    })

                    if (index > -1) {
                        totals_temp.splice(index, 1);
                    }
                    totals_temp.push({
                        percent: item.vat_percent,
                        value: parseFloat((item.qty * item.price) + parseFloat(currentTotal.value)).toFixed(2)
                    })
                } else {
                    vats_temp.push({
                        percent: item.vat_percent, value: parseFloat(item.qty * item.price * (item.vat_percent / 100)).toFixed(2)
                    })
                    totals_temp.push({
                        percent: item.vat_percent, value: parseFloat(item.qty * item.price).toFixed(2)
                    })
                }
            }
        })
        totals_temp.sort((a, b) => (a.percent > b.percent) ? 1 : -1)
        vats_temp.sort((a, b) => (a.percent > b.percent) ? 1 : -1)
        setVats(vats_temp)
        setTotals(totals_temp)
        setUpdate(update + 1)
    }
    useEffect(() => {
        setVatValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total, items])
    if (loading) {
        return <LoadingComp/>
    }
    const SingleValue = props => (<components.SingleValue  {...props}>
            <span>{intl.formatMessage({id: 'general.select'})}</span>
        </components.SingleValue>);


    return (<div className="card add-invoice">
            <div className={"header"}><p>{intl.formatMessage({id: 'invoices.add_invoice'})}</p></div>
            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <InvoiceInfo
                            invoiceSeries={series}
                            series={seriesOptions}
                            event={event}
                            setInvoiceSeries={setSeries}
                            setInvoiceNumber={setNumber}
                            invoiceNumber={number}
                            invoiceDate={moment(date).format('DD/MM/YYYY')}
                            clientName={event.client_type === 'company' ? event.client.company_title : event.client.first_name + ' ' + event.client.last_name}
                            clientCui={event.client_type === 'company' ? event.client.fiscal_code : ''}
                            clientAddress={getAddress(event.client)}
                            companyCui={event.company.fiscal_code}
                            companyName={event.company.title}
                            companyEmail={event.company.mailjet_sender_email ?? (event.company.users.length > 0 ? event.company.users[0].email : '')}
                            addressStreet={event.company.address}
                            addressCityStateZip={event.company.city + ', ' + event.company.county}
                        />


                        <div className={"col-6"}>
                            <div className={"form-control"}>

                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoices.invoice_date'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={date}
                                    dateFormat={"dd/MM/yyyy"}
                                    minDate={new Date()}
                                    disabled
                                    requires
                                />
                            </div>
                        </div>
                        <div className={"col-6"}>

                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoices.due_by'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={dueDate}
                                    dateFormat={"dd/MM/yyyy"}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                        context.setContentUpdated(true)
                                        setDueDate(date)
                                    }}
                                    requires
                                />
                            </div>
                        </div>
                        <div className={"col-6 mt-4"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.add_elemente'})}</div>
                                <Selector
                                    options={itemsOptions}
                                    value={itemsSelector}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setItemsSelector([])
                                        let temp = vatsOptions.filter(item => item.percent === option.vat)
                                        if (temp && temp.length > 0) {
                                            option.vat_percent = temp[0].percent
                                            option.vat = temp[0].value
                                            option.vat_name = temp[0].name
                                        }
                                        setItems(items.concat([option]))
                                        setTotal(parseFloat(total + option.qty * option.price))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                    components={{SingleValue}}
                                />
                            </div>
                        </div>

                        <LineItems
                            items={items}
                            setItems={setItems}
                            setTotal={setTotal}
                            total={total}
                            vatsOptions={vatsOptions}
                        />

                        <InvoiceTable
                            hasBottomBorder={true}
                            headings={(<>
                                    <th>{intl.formatMessage({id: 'invoices.payment_info'})}</th>
                                    <th className={"right-aligned-cell"}>{intl.formatMessage({id: 'invoices.due_by'})}</th>
                                    <th>{intl.formatMessage({id: 'invoices.total'})}</th>
                                </>)}
                        >
                            <tr>
                                <td className={"payment-info"}>
                                    <div>
                                        {intl.formatMessage({id: 'invoices.account_no'})} : <strong>
                                        {event.company.bank_accounts.map((item, i) => {
                                            return item.iban + ((i + 1) !== event.company.bank_accounts.length ? ' / ' : '')
                                        })}</strong>
                                    </div>
                                </td>
                                <td className={"right-aligned-cell"}>
                                    <span>{moment(dueDate).format('DD/MM/YYYY')}</span></td>
                                <td className={"total right-aligned-cell"} key={update}>
                                    <span className={"large"}>
                                        <strong>{parseFloat(total).toLocaleString('ro-RO')} RON</strong>
                                    </span>


                                    {vats.map((vat) => (<div className={"vat-container"} key={vat.percent}>
                                            <div
                                                className={"pe-3"}>{intl.formatMessage({id: 'general.vat'})}({vat.percent}%)
                                            </div>
                                            <div>{parseFloat(vat.value).toLocaleString('ro-RO')}</div>
                                        </div>))}
                                </td>
                            </tr>
                        </InvoiceTable>

                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                type={'submit'}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />
                        </div>
                        {window.alityAppView && <div className={"col-12"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {

                                    context.setLeavePage(true)
                                    context.setNextLink(-1)
                                }}
                            />
                        </div>}
                    </div>
                </div>
            </form>


            <ModalComp
                title={intl.formatMessage({id: 'invoices.want_cancel_invoice'})}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className={"subscription-limit"}
            >
                <div className={"content"}>
                    {

                        /*eslint-disable */
                        //suppress all warnings between comments
                        invoices.map((invoice) => (<div className={"col-12"}
                                                        key={invoice.id}>
                            <div className={"form-control"}>
                                <label className={"form-label"}>
                                    <input type={"checkbox"}
                                           onChange={(e) => {
                                               if (e.target.checked) {
                                                   let temp = []
                                                   for (let i = 0; i < invoice.content.totals.length; i++) {
                                                       temp.push({
                                                           value: 'advance',
                                                           label: intl.formatMessage({id: 'invoices.invoice'}) + ' ' + invoice.series + invoice.number + '/' + invoice.date,
                                                           price: parseFloat(parseFloat(invoice.content.totals[i].value * ( invoice.content.company.currency === 'euro' ? eur : 1)).toFixed(2)),
                                                           qty: -1,
                                                           type: '',
                                                           id: 0,
                                                           vat: invoice.content.totals[i].percent
                                                       })
                                                   }
                                                   setItems(items.concat(temp))

                                                   setTotal(total - invoice.total)
                                               } else {
                                                   const temp = [...items]
                                                   const index = temp.map(e => e.label).indexOf(intl.formatMessage({id: 'invoices.invoice'}) + ' ' + invoice.series + invoice.number + '/' + invoice.date);

                                                   if (index > -1) {
                                                       temp.splice(index, 1);
                                                   }
                                                   setItems(temp)
                                                   setTotal(total + invoice.total)
                                               }

                                           }}/>
                                    {invoice.series}{invoice.number} / {invoice.date} ({parseFloat(invoice.total * ( invoice.content.company.currency === 'euro' ? eur : 1)).toLocaleString('ro-RO')} ron)
                                </label>

                            </div>
                        </div>))}
                </div>
                <div className={"footer d-flex"}>
                    <Button
                        className={"btn-secondary mt-2 ms-auto"}
                        icon={"fa-regular fa-floppy-disk"}
                        value={intl.formatMessage({id: 'general.buttons.save'})}
                        onClick={() => {
                            setModalIsOpen(false)
                        }}
                    />

                </div>

            </ModalComp>
        </div>);
}

