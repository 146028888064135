import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function Notifications() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [notifications, setNotifications] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/notification-configurations', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setNotifications(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const deleteItem = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/notification-configurations/' + itemId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpen(false)
                setItemId(0)
                setLoadingDelete(false)

            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete(false)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header align-items-center"}>
                <div></div>
                <Button
                    className={"d-block btn-primary ms-auto"}
                    icon={"fa-solid fa-plus"}
                    value={intl.formatMessage({id: 'settings.notifications.config_notification'})}
                    onClick={() => navigate('/notifications/configuration/add')}
                />
            </div>


            {
                notifications.length === 0 ?
                    <div className={"content"}>
                        <div className={"alert alert-warning"}>
                            {intl.formatMessage({id: 'settings.notifications.alert_no_notifications'})}
                        </div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.notifications.type'})}</div>
                                    <div className={"col-2 table-td"}>{intl.formatMessage({id: 'settings.notifications.days'})}</div>
                                    <div className={"col-2 table-td"}>{intl.formatMessage({id: 'settings.notifications.when'})}</div>
                                    <div className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.notifications.trigger'})}</div>
                                    <div className={"col-2 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    notifications.map((notification, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.notifications.type_' + notification.type})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{notification.days !== 0 ? notification.days : ''}</div>
                                                <div
                                                    className={"col-2 table-td"}>{notification.when && intl.formatMessage({id: 'settings.notifications.' + notification.when})}</div>
                                                <div
                                                    className={"col-3 table-td"}>{notification.event !== "0" && intl.formatMessage({id: 'settings.notifications.' + notification.event})}</div>
                                                <div className={"col-2 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/notifications/configuration/' + notification.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        icon={"fa-solid fa-trash"}
                                                        onClick={() => {
                                                            setModalIsOpen(true)
                                                            setItemId(notification.id)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer"}>

            </div>
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => {
                    setModalIsOpen(false)
                    setItemId(0)
                }}
                onConfirm={() => deleteItem()}
            />
        </div>

    );
}

