import {useIntl} from "react-intl";
import {
    getNotificatioLink,
    deleteNotification,
    markNotificationAsDone,
    getNotificatioText
} from "../../../utils/functions";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import React, {useState} from "react";

function Notification({notification, getData, setNotificationsShow, notificationsShow}) {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)

    return (<div key={notification.id} className={"notification-container " + notification.status}
                 style={{cursor: getNotificatioLink(notification.type, notification.data) !== '/' ? 'pointer' : ''}}>
            <div onClick={() => {
                setNotificationsShow(!notificationsShow)
                getData()
                if (getNotificatioLink(notification.type, notification.data) !== '/')
                    navigate(getNotificatioLink(notification.type, notification.data))
            }
            }>

                {(() => {
                    switch (notification.type) {
                        case 1:
                        case 13:
                        case 14:
                        case 15:
                        case 24:
                            return <i className="fa-solid fa-clock"></i>
                        case 3:
                        case 8:
                        case 9:
                        case 16:
                        case 17:
                            return <i className="fa-regular fa-file"></i>
                        case 5:
                            return <i className="fa-solid fa-bell"></i>
                        case 6:
                            return <i className="fa-solid fa-alarm-clock"></i>
                        case 7:
                        case 10:
                        case 12:
                            return <i className="fa-regular fa-file-lines"></i>
                        case 2:
                        case 11:
                        default:
                            return <i className="fa-regular fa-calendar-check"></i>
                    }
                })()}

                {(() => {
                    return getNotificatioText(notification, intl)

                })()}
            </div>
            { notification.type === 6 && notification.status !== 'done' && <i data-tooltip-id={"edit"} className="edit fa-solid fa-pen me-0 ms-3"
                  onClick={async () => {
                      setNotificationsShow(!notificationsShow)
                      navigate('/notifications/custom/' + notification.id)
                  }}></i>}
            {notification.status !== 'done' && (
                loading ?
                    <i data-tooltip-id={"check"}
                       style={{position: 'absolute', right: '44px', top: '20px'}}
                       className="fa-solid fa-spinner me-0 ms-3"/>
                    :
                    <i data-tooltip-id={"check"} className="check fa-solid fa-check me-0 ms-3"
                       onClick={async () => {
                           setLoading(true)
                           await markNotificationAsDone(notification.id, intl, jwt, () => {
                               getData()
                               setLoading(false)
                           })
                       }}></i>)}
            {loadingDelete ?
                <i data-tooltip-id={"check"}
                   style={{position: 'absolute', right: '20px', top: '20px'}}
                   className="fa-solid fa-spinner me-0 ms-3"/>
                :
                <i data-tooltip-id={"delete"} className="delete fa-solid fa-xmark-circle me-0 ms-3"
                   onClick={async () => {
                       setLoadingDelete(true)
                       await deleteNotification(notification.id, intl, jwt, () => {
                           getData()
                           setLoadingDelete(false)
                       })
                   }}></i>
            }

            <Tooltip id={"check"}>{intl.formatMessage({id: 'general.buttons.done'})}
            </Tooltip>
            <Tooltip id={"edit"}>{intl.formatMessage({id: 'general.buttons.edit'})}
            </Tooltip>
            <Tooltip id={"delete"}>{intl.formatMessage({id: 'general.buttons.delete'})}
            </Tooltip>
        </div>
    );
}

export default Notification;
