import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function Sources() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [sources, setSources] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/event-sources', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setSources(response.data.sources ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const deleteItem = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/event-sources/' + itemId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                }else{

                    toast.error(intl.formatMessage({id: 'settings.sources.error_delete_source'}))
                }
                setModalIsOpen(false)
                setItemId(0)
                setLoadingDelete(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete(false)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.event_sources'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header align-items-center"}>
                            <div></div>
                            <Button
                                className={"d-block btn-primary ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/sources/add')}
                            />
                        </div>


                        {
                            sources.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'settings.sources.alert_no_sources'})}
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-6 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                                <div className={"col-6 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                sources.map((source, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-6 table-td"}>{source.title}</div>
                                                            <div className={"col-6 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/sources/' + source.id)}
                                                                />

                                                                <Button
                                                                    className={"btn-light btn-delete ms-2"}
                                                                    tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                    icon={"fa-solid fa-trash"}
                                                                    onClick={() => {
                                                                        setModalIsOpen(true)
                                                                        setItemId(source.id)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer"}>

                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmDelete
            loading={loadingDelete}
            modalIsOpen={modalIsOpen}
            title={intl.formatMessage({id: 'general.delete'})}
            onRequestClose={() => {
                setModalIsOpen(false)
                setItemId(0)
            }}
            onConfirm={() => deleteItem()}
        />
        </div>
    );
}

