import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../../components/elements/Paginate/Paginate";
import Selector from "../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function CostsList() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [costs, setCosts] = useState([])
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalCosts, setTotalCosts] = useState(0)
    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])
    const currency = localStorage.getItem('currency')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page, category])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/costs/list/' + page, {search, category}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setCosts(response.data.costs ?? [])
                setTotalCosts(response.data.total ?? 0)
                setLoading(false)
                getCategories()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCategories = async () => {
        axios.get(process.env.REACT_APP_API + '/cost-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                items.push({
                    value: '',
                    label: intl.formatMessage({id: 'general.all_categories'})
                })
                response.data.categories.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setCategories(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    const deleteItem = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/costs/' + itemId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'costs.error_delete_cost'}))
                }
                setModalIsOpen(false)
                setItemId(0)
                setLoadingDelete(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete(false)
            })
    }
    return (
        <div className="card">

            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-lg-4 col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-lg-4 col-md-6 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <Selector
                                options={categories}
                                value={category}
                                onChange={(option) => setCategory(option.value)}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-lg-4 col-sm-12 float-right"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/costs/list/add')}
                        />
                    </div>
                </div>
            </div>


            {
                costs.length === 0 ?
                    search === '' && category === '' ?
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'costs.alert_no_costs'})}</div>
                        </div>
                        :
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>{intl.formatMessage({id: 'costs.alert_no_costs_found'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.name'})}</div>
                                    <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.value'})}</div>

                                    <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.category'})}</div>
                                    <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.events'})} / {intl.formatMessage({id: 'general.locations'})}</div>
                                    <div className={"col-2 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    costs.map((cost, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1 + (page - 1) * 10}
                                                </div>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {cost.title}
                                                </div>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {cost.value} {intl.formatMessage({id: 'settings.currency.' + currency})}
                                                </div>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {cost.category ? cost.category.title : ''}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {cost.type === 'events' && cost.events.map((item,i) => {
                                                        if (i === cost.events.length -1){
                                                            return item.title
                                                        }else{
                                                            return item.title + ', '
                                                        }
                                                    })}
                                                    {cost.type === 'locations' && cost.locations.map((item,i) => {
                                                        if (i === cost.locations.length -1){
                                                            return item.title
                                                        }else{
                                                            return item.title + ', '
                                                        }
                                                    })}
                                                </div>
                                                <div className={"col-2 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/costs/list/' + cost.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        onClick={() => {
                                                            setModalIsOpen(true)
                                                            setItemId(cost.id)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>

                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalCosts}
                />
            </div>
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => {
                    setModalIsOpen(false)
                    setItemId(0)
                }}
                onConfirm={() => deleteItem()}
            />
        </div>

    );
}

