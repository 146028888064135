import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import Paginate from "../../../../../components/elements/Paginate/Paginate";
import {toast} from "react-toastify";
import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function MenuProducts() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [selectedProducts, setSelectedProducts] = useState([])
    const [products, setProducts] = useState([])
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [totalProducts, setTotalProducts] = useState(0)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modal2IsOpen, setModal2IsOpen] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/menu-products/list/' + page, {search}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(async (response) => {
            setProducts(response.data.products ?? [])
            setTotalProducts(response.data.total ?? 0)
            setLoading(false)
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }
    const deleteItem = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/menu-products/' + itemId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'menus.error_delete_product'}))
                }
                setModalIsOpen(false)
                setItemId(0)
                setLoadingDelete(false)
            })
            .catch(err => {
                setLoadingDelete(false)
                errorParser(err, intl)
            })
    }
    const deleteProducts = async () => {
        setLoadingDelete(true)
        axios.post(process.env.REACT_APP_API + '/menu-products/delete-list' , {ids: selectedProducts}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    if (response.data.message.length > 0)
                        toast.error(intl.formatMessage({id: 'menus.delete_products'})  + response.data.message.slice(0, response.data.message.length-2) + '!')
                    else
                        toast.error(intl.formatMessage({id: 'menus.error_delete_products'}))

                    getData()
                }
                setSelectedProducts([])
                setModal2IsOpen(false)
                setLoadingDelete(false)
            })
            .catch(err => {
                setLoadingDelete(false)
                errorParser(err, intl)
            })
    }
    const toggleSelectProduct = (id) => {

        if (selectedProducts.includes(id)) {
            setSelectedProducts(selectedProducts.filter((item) => item !== id))
        } else {
            setSelectedProducts([...selectedProducts, id])
        }

    }
    return (

        <div className="card">

            <div className={"header align-items-center"}>
                <div className={"row"}>
                    <div className={"col-md-4 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-md-8 col-sm-12"}>
                        <div className={"d-flex"}>
                            <Button
                                className={"d-block btn-primary ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/menus/menu-products/add')}
                            />
                            <Button
                                className={"d-block btn-primary ms-2"}
                                icon={"fa-solid fa-upload"}
                                value={intl.formatMessage({id: 'general.import'}) + ' (Ebriza)'}
                                onClick={() => navigate('/menus/menu-products/import')}
                            />
                            <Button
                                className={"d-block btn-error ms-2 " + (selectedProducts.length === 0 ? 'disabled' : '')}
                                value={intl.formatMessage({id: 'general.delete'})}
                                onClick={() => setModal2IsOpen(true)}
                                disabled={selectedProducts.length === 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                products.length === 0 ?
                    <div className={"content"}>
                        <div className={"alert alert-warning"}>
                            {intl.formatMessage({id: 'menus.alert_no_products'})}
                        </div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-1 table-td"}>
                                        <input type={"checkbox"}
                                               onChange={() => {
                                                   if (selectedProducts.length === products.length) {
                                                       setSelectedProducts([])
                                                   } else {
                                                       setSelectedProducts(products.map((item) => item.id))
                                                   }
                                               }}
                                               value={'all'}
                                               checked={selectedProducts.length > 0 && selectedProducts.length === products.length}
                                        />
                                    </div>
                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'menus.products.type'})}</div>
                                    <div className={"col-3 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    products.map((product, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    <input type={"checkbox"}
                                                           className={"me-2"}
                                                           onChange={() => toggleSelectProduct(product.id)}
                                                           value={product.id}
                                                           checked={selectedProducts.includes(product.id)}/>
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>{product.title}</div>
                                                <div
                                                    className={"col-4 table-td"}>{intl.formatMessage({id: 'menus.products.type.' + product.type})}</div>
                                                <div className={"col-3 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        icon={"fa-solid fa-pen"}
                                                        onClick={() => navigate('/menus/menu-products/' + product.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete ms-2"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        icon={"fa-solid fa-trash"}
                                                        onClick={() => {
                                                            setModalIsOpen(true)
                                                            setItemId(product.id)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalProducts}
                />
            </div>
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => {
                    setModalIsOpen(false)
                    setItemId(0)
                }}
                onConfirm={() => deleteItem()}
            />
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modal2IsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => {
                    setModal2IsOpen(false)
                }}
                onConfirm={() => deleteProducts()}
            />
        </div>

    );
}

