import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function BankAccounts() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [bankAccounts, setBankAccounts] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/bank-accounts', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setBankAccounts(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const deleteItem = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/bank-accounts/' + itemId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpen(false)
                setItemId(0)
                setLoadingDelete(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete(false)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header align-items-center"}>

                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12"}>
                        <p>{intl.formatMessage({id: 'settings.menu.bank_accounts'})}</p>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <Button
                            className={"d-block btn-primary ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/company/bank-accounts/add')}
                        />
                    </div>
                </div>
            </div>


            {
                bankAccounts.length === 0 ?
                    <div className={"content"}>
                        <div className={"alert alert-warning"}>
                            {intl.formatMessage({id: 'settings.bank_accounts.alert_no_bank_accounts'})}
                        </div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'settings.bank_accounts.bank'})}</div>
                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'settings.bank_accounts.iban'})}</div>
                                    <div className={"col-4 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    bankAccounts.map((bankAccount, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-4 table-td"}>{bankAccount.bank}</div>
                                                <div
                                                    className={"col-4 table-td"}>
                                                    {bankAccount.iban}</div>
                                                <div className={"col-4 table-td"}>

                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/company/bank-accounts/' + bankAccount.id)}
                                                    />  
                                                    <Button
                                                    className={"btn-light btn-delete"}
                                                    icon={"fa-solid fa-trash"}
                                                    tooltip={intl.formatMessage({id: 'general.delete'})}
                                                    onClick={() => {
                                                        setModalIsOpen(true)
                                                        setItemId(bankAccount.id)
                                                    }}
                                                />

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer"}>

            </div>
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => {
                    setModalIsOpen(false)
                    setItemId(0)
                }}
                onConfirm={() => deleteItem()}
            />
        </div>

    );
}

