import React, {useEffect, useState} from 'react'
import moment from "moment/moment";
import {useIntl} from "react-intl";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Selector from "../../../components/elements/Selector/Selector";
import {components} from "react-select";
import LoadingCompHover from "../../../components/elements/LoadingCompHover/LoadingCompHover";
import Paginate from "../../../components/elements/Paginate/Paginate";
import {NavLink, useNavigate} from "react-router-dom";
import Button from "../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import {eventTypeCatering, eventTypeSalon} from "../../../utils/constants";
import ModalConfirmDelete from "../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function EventsEventPlanner() {
    const intl = useIntl()
    const jwt = localStorage.getItem('jwt')
    const now = new Date()
    const navigate = useNavigate()
    let firstYear = now.getFullYear()
    const companyType = localStorage.getItem('type')

    const monthsOptions = []
    for (let i = 0; i < 12; i++) {
        monthsOptions.push({
            label: capitalizeFirstLetter(moment(new Date(firstYear, i, 1)).format('MMMM')),
            value: i
        })
    }

    const daysOptions = []
    for (let i = 1; i < 8; i++) {
        daysOptions.push({
            label: capitalizeFirstLetter(moment().day(i).format('dddd')),
            value: i
        })
    }

    const [yearsOptions, setYearsOptions] = useState([])
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [loading, setLoading] = useState(true)
    const [year, setYear] = useState(firstYear)
    const [month, setMonth] = useState('')
    const [day, setDay] = useState(Array.from(daysOptions, option => parseInt(option.value)))
    const [status, setStatus] = useState('')
    const [client, setClient] = useState('')
    const [invoice, setInvoice] = useState('')
    const [contract, setContract] = useState('')
    const [eventTypeOptions, setEventTypeOptions] = useState([])
    const [eventType, setEventType] = useState([])
    const [user, setUser] = useState('all')
    const [users, setUsers] = useState([])
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])

    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (user !== 'all')
                getItems()
        }, 600)

        return () => clearTimeout(delayDebounceFn)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventType, year, month, day, status, tags, invoice, contract, page, client, user])
    useEffect(() => {
        if (companyType === 'event_planner') {
            getEventTypes()
        } else {
            let temp = []
            for (let i = 0; i < eventTypeSalon.length; i++) {
                temp.push({
                    value: eventTypeSalon[i],
                    label: intl.formatMessage({id: 'general.event_type.' + eventTypeSalon[i]})
                })
            }
            for (let i = 0; i < eventTypeCatering.length; i++) {
                temp.push({
                    value: eventTypeCatering[i],
                    label: intl.formatMessage({id: 'general.event_type.' + eventTypeCatering[i]})
                })
            }
            setEventTypeOptions(temp)

            if (eventType.length !== temp.length)
                setEventType(Array.from(temp, item => item.value))
        }

        getUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEventTypes = async () => {
        axios.get(process.env.REACT_APP_API + '/event-type-for-event-planners', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.length; i++) {
                    temp.push({
                        value: response.data[i].id,
                        label: response.data[i].title
                    })
                }
                setEventTypeOptions(temp)
                setEventType(Array.from(temp, item => item.value))
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getUsers = async () => {
        axios.get(process.env.REACT_APP_API + '/users-settings', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.first_name + ' ' + item.last_name,
                        image: item.image,
                        icon: item.icon,
                    })
                })
                setUsers(items)
                setUser(Array.from(items, user => parseInt(user.value)))

                getTags()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getTags = async () => {
        axios.get(process.env.REACT_APP_API + '/event-tags', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.tags.length; i++) {
                    temp.push({
                        value: response.data.tags[i].id,
                        label: response.data.tags[i].title
                    })
                }
                setTagsOptions(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getItems = async () => {
        if (day.length !== 0) {
            day.sort((a, b) => (a > b) ? 1 : -1)
            setLoading(true)
            let payload = {
                year: year,
                month: month,
                day: day,
                availability: 'busy',
                status: status,
                invoice: invoice,
                contract: contract,
                user: user,
                tags,
                event_type: eventType
            }

            if (client !== '') {
                payload.client = client
            }

            axios.post(process.env.REACT_APP_API + '/events/agenda-for-event-planner/' + page, payload, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {

                    setItems(response.data.items)
                    setLoading(false)
                    setTotalItems(response.data.total)

                    let temp = []
                    let lastYear = moment(response.data.end).get('year') + 2
                    let firstYear1 = moment(response.data.start).get('year')
                    for (let i = 0; i <= lastYear - firstYear1; i++)
                        temp.push({
                            label: firstYear1 + i,
                            value: firstYear1 + i
                        })

                    setYearsOptions(temp)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
        } else {
            setItems([])
            setLoading(false)
        }
    }
    const deleteItem = async () => {
        setLoadingDelete(true)
        await axios.delete(process.env.REACT_APP_API + '/events/' + itemId, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getItems()
                setModalDeleteIsOpen(false)
                setItemId(0)
                setLoadingDelete(false)
            })
            .catch(err => {
                setLoadingDelete(false)
                errorParser(err, intl)
            })
    }
    const duplicateEvent = async (id) => {
        await axios.get(process.env.REACT_APP_API + '/duplicate-event/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                if (response.data.event) {
                    navigate('/events/' + response.data.event.id)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );


    return (
        <div className="container">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'events.events_tittle'})}</h1>
                    {/*  */}
                </div>

                <div className={"col-12"}>
                    <div className={"card agenda-container mt-0 p-0"}
                         style={{position: 'relative'}}>
                        {
                            loading &&
                            <LoadingCompHover/>
                        }
                        <div className={"header mb-3 mt-3"}>
                            <div className={"row pb-3"}>
                                <div className={"col-lg-6 col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'menus.event_type'})}</div>
                                    <Selector
                                        options={eventTypeOptions}
                                        value={eventType}
                                        onChange={(option) => {
                                            if (option[0] && option[option.length - 1].value === 'all') {
                                                setEventType(Array.from(eventTypeOptions, type => type.value))
                                            } else {
                                                setEventType(Array.from(option, option => option.value))
                                            }
                                        }}
                                        onMenuClose={() => {
                                            setEventType(eventType)
                                        }}
                                        styles={{
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                textOverflow: "ellipsis",
                                                maxWidth: "90%",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                display: "initial"
                                            })
                                        }}
                                        allowSelectAll={true}
                                        hideSelectedOptions={false}
                                        isSearchable={false} isMulti
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                                <div className={"col-lg-3 col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.year'})}</div>
                                    <Selector
                                        options={[...yearsOptions]}
                                        value={year}
                                        onChange={(option) => {
                                            setPage(1)
                                            setYear(option.value)
                                        }}
                                        key={year + yearsOptions.length}
                                        isSearchable={false}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-3 col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.month'})}</div>
                                    <Selector
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.all_months'})
                                        }, ...monthsOptions]}
                                        value={month}
                                        onChange={(option) => {
                                            setPage(1)
                                            setMonth(option.value)
                                        }}
                                        isSearchable={false}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-6 col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.day'})}</div>
                                    <Selector
                                        key={day.length}
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.select_all'})
                                        }, ...daysOptions]}
                                        value={day}
                                        onChange={(option) => {
                                            setPage(1)

                                            if (option[0] && option[option.length - 1].value === '') {
                                                setDay(Array.from(daysOptions, option => parseInt(option.value)))
                                            } else {
                                                setDay(Array.from(option, option => parseInt(option.value)))
                                            }
                                        }}
                                        isMulti
                                        isSearchable={false}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-6 col-12 mt-3"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.search_by_client'})}</div>
                                    <input type={"text"} className={"form-input"}
                                           placeholder={intl.formatMessage({id: 'general.first_last_name_email_phone'})}
                                           value={client}
                                           onChange={(e) => setClient(e.target.value)}/>
                                </div>
                                <div className={"col-lg-6 col-12 mt-3"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.added_by'})}</div>
                                    <Selector
                                        options={users}
                                        value={user}
                                        isMulti
                                        onChange={(option) => {
                                            if (option[0] && option[option.length - 1].value === '') {
                                                setUser(Array.from(daysOptions, option => parseInt(option.value)))
                                            } else {
                                                setUser(Array.from(option, option => parseInt(option.value)))
                                            }
                                        }}
                                        isSearchable={false}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                                <div className={"col-lg-4 col-12 mt-3"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.status'})}</div>
                                    <Selector
                                        options={[{
                                            value: 'booked',
                                            label: intl.formatMessage({id: 'events.status.booked'})
                                        }, {
                                            value: 'confirmed',
                                            label: intl.formatMessage({id: 'events.status.confirmed'})
                                        }, {
                                            value: 'canceled',
                                            label: intl.formatMessage({id: 'events.status.canceled'})
                                        }]}
                                        value={status}
                                        onChange={(option) => {
                                            setPage(1)
                                            setStatus(option.value)
                                        }}
                                        isSearchable={false}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>
                                <div className={"col-lg-4 col-12 mt-3"}>

                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.tags'})}</div>
                                    <Selector
                                        options={tagsOptions}
                                        value={tags}
                                        onChange={(option) =>
                                            setTags(Array.from(option, option => option.value))}
                                        isSearchable={false}
                                        isOptionDisabled={(option) => option.disabled}
                                        isMulti
                                    />
                                </div>
                                <div className={"col-lg-4 col-12 mt-3"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.invoice'})}</div>
                                    <Selector
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.all_options'})
                                        }, {
                                            value: 1,
                                            label: intl.formatMessage({id: 'invoices.status.issued'})
                                        }, {
                                            value: 0,
                                            label: intl.formatMessage({id: 'invoices.status.unissued'})
                                        }]}
                                        value={invoice}
                                        onChange={(option) => {
                                            setPage(1)
                                            setInvoice(option.value)
                                        }}
                                        isSearchable={false}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>

                                <div className={"col-lg-4 col-12 mt-3"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.contract'})}</div>
                                    <Selector
                                        options={[{
                                            value: '',
                                            label: intl.formatMessage({id: 'general.all_options'})
                                        }, {
                                            value: 'signed',
                                            label: intl.formatMessage({id: 'events.signed_contract'})
                                        }, {
                                            value: 'unsigned',
                                            label: intl.formatMessage({id: 'events.unsigned_contract'})
                                        }]}
                                        value={contract}
                                        onChange={(option) => {
                                            setPage(1)
                                            setContract(option.value)
                                        }}
                                        isSearchable={false}
                                        hideSelectedOptions={false}
                                        components={{Option, MultiValue}}
                                    />
                                </div>

                            </div>
                        </div>
                        {items.length > 0 && <div className={"content p-0"}>

                            <div className={"table events mb-1"}>
                                <div className={"table-head"}>
                                    <div className={"row"}>
                                        <div className={"col-1 table-td"}>
                                        </div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.day'})}</div>
                                        <div
                                            className={"col-2 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.client'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.phone'})}</div>
                                        <div
                                            className={"col-2 table-td"}>{intl.formatMessage({id: 'general.email'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr_contract'})}</div>
                                        <div
                                            className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr_invoice'})}</div>
                                        <div className={"col-1 table-td"}></div>

                                    </div>
                                </div>
                                <div className={"table-body"}>

                                    {items && items.map((item, i) => (
                                        <div className={"table-row"} key={'it' + i}>
                                            {
                                                item.events.map((event, j) => (
                                                    <div className={"row"} key={'loc' + j}>
                                                        <div className={"col-1 table-td"}>
                                                        </div>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + event.id}>
                                                            {j === 0 && moment(item.date).format('ddd, Do MM YYYY')}
                                                        </NavLink>
                                                        <NavLink className={"col-2 table-td"}
                                                                 to={"/events/" + event.id}>
                                                            <div>
                                                                <div>
                                                                    {event.event_type_for_event_planner ? event.event_type_for_event_planner.title : ''}
                                                                    : {event.title}
                                                                </div>
                                                                <div className={"d-flex"}>
                                                                    {
                                                                        event.tags && event.tags.map(tag => (
                                                                            <div className={"tag-badge"}
                                                                                 key={'tag' + event.title + tag.title}
                                                                                 style={{backgroundColor: tag.color}}></div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + event.id}>
                                                            <div
                                                                className={"alert alert-sm alert-" + (event && event.status === 'booked' ? 'warning' : (event.status === 'confirmed' ? 'success' : 'danger'))}>
                                                                {intl.formatMessage({id: 'events.status.' + event.status})}
                                                            </div>
                                                        </NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + event.id}>
                                                            {event.client && ((event.client.first_name ?? '') + ' ' + (event.client.last_name ?? ''))}</NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + event.id}>
                                                            {event.client && event.client.phone}</NavLink>
                                                        <NavLink className={"col-2 table-td"}
                                                                 to={"/events/" + event.id}>
                                                            {event.client && event.client.email}</NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + event.id + '/contracts'}>
                                                            {
                                                                event.contracts.map((contract, i) => {
                                                                    return (contract.series + contract.number + (i !== event.contracts.length - 1 ? ', ' : ''))
                                                                })
                                                            }
                                                        </NavLink>
                                                        <NavLink className={"col-1 table-td"}
                                                                 to={"/events/" + event.id + '/invoices'}>
                                                            {
                                                                event.invoices.map((invoice, i) => {
                                                                    return (invoice.series + invoice.number + (i !== event.invoices.length - 1 ? ', ' : ''))
                                                                })
                                                            }
                                                        </NavLink>
                                                        <div className={"col-1 table-td"}>

                                                            <Button
                                                                disabled={event.status !== 'booked'}
                                                                className={"btn-light btn-delete " + (event.status !== 'booked' ? 'disabled' : '')}
                                                                tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                icon={"fa-solid fa-trash"}
                                                                type={'button'}
                                                                onClick={() => {
                                                                    setModalDeleteIsOpen(true)
                                                                    setItemId(event.id)
                                                                }}
                                                            />
                                                            <Button
                                                                className={"btn-light btn-more"}
                                                                icon={"fa-solid fa-copy"}
                                                                tooltip={intl.formatMessage({id: 'general.copy'})}
                                                                onClick={() => duplicateEvent(event.id)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    ))}
                                </div>
                            </div>

                        </div>}
                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                key={page}
                                page={page}
                                setPage={setPage}
                                totalItems={totalItems}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalDeleteIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => {
                    setModalDeleteIsOpen(false)
                    setItemId(0)
                }}
                onConfirm={() => deleteItem()}
            />
        </div>)

}
